import { useState, useCallback } from 'react';

import { ApiError } from './ApiError';
import { useInjectParams } from './request-helpers';
import {
  UseMutationHook,
  UseMutationHookSend,
  UseMutationHookReturnValue,
  UnwrapBody,
  DCServiceCall,
  InjectionInfo,
} from './types';

export function createMutationHook<T extends DCServiceCall, I extends InjectionInfo>(
  dcServiceCall: T,
  injectionInfo: I,
): UseMutationHook<T, I> {
  const useMutation = () => {
    const [data, setData] = useState<Awaited<ReturnType<T>>>();
    const [error, setError] = useState<ApiError | null>(null);
    const [isLoading, setLoading] = useState(false);
    const [isIdle, setIdle] = useState(true);
    const reset = () => {
      setIdle(true);
      setLoading(false);
      setError(null);
    };

    const injectedParams = useInjectParams(injectionInfo);
    const isSuccess = !isLoading && !error && !isIdle;
    const isError = !!error && !isLoading;

    const status = (isIdle && 'idle') || (isLoading && 'loading') || (isSuccess && 'success') || (isError && 'error');

    const send: UseMutationHookSend<UnwrapBody<T, I>, Awaited<ReturnType<T>>> = useCallback(
      async requestBody => {
        setLoading(true);
        setError(null);
        setIdle(false);
        try {
          const mutation = await dcServiceCall({
            ...injectedParams.injected,
            ...requestBody,
          });
          setData(mutation);
          return mutation;
        } catch (error: unknown) {
          setError(error as ApiError);
        } finally {
          setLoading(false);
        }
      },
      [injectedParams.injected],
    );

    return { send, error, data, isLoading, isIdle, isSuccess, isError, status, reset } as UseMutationHookReturnValue<
      UnwrapBody<T, I>,
      Awaited<ReturnType<T>>
    >;
  };

  return useMutation;
}
